import * as React from "react"

import Employer from "./employer"

const Employers = ({ employers, projects }) => {
  return (
    <div>
      {employers.map(e => (
        <Employer employer={e} projects={projects} />
      ))}
    </div>
  )
}

export default Employers
