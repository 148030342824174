import * as React from "react"

import Project from "./project"

const Projects = ({ projects }) => {
  return (
    <div>
      {projects.map(p => (
        <Project project={p} />
      ))}
    </div>
  )
}

export default Projects
