import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

const Hero = ({ children, className }) => {
  const {
    desktopImage: {
      childImageSharp: { fluid: desktopFluid },
    },
  } = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "header-cv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={classnames("relative h-screen max-h-screen", className)}>
      <div className="absolute top-0 left-0 block w-screen h-screen">
        <Img
          className="block h-screen md:hidden"
          fluid={desktopFluid}
          style={{
            filter: "opacity(66%) blur(2px)",
          }}
        />
        <Img
          className="hidden h-screen md:block"
          fluid={desktopFluid}
          style={{
            filter: "opacity(66%) blur(2px)",
          }}
        />
      </div>
      <div className="absolute top-0 bottom-0 w-full h-screen pl-4 text-left md:text-right md:top-auto md:left-auto md:right-0 md:pr-20">
        {children}
      </div>
    </div>
  )
}

export default Hero
