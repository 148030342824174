import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import ContentContainer from "../../components/content"

import { H2, PageBreak } from "../../components/typography"

import Contact from "../../components/cv/contact"
import Intro from "../../components/cv/intro"
import Projects from "../../components/cv/projects"
import Skills from "../../components/cv/skills"
import Employers from "../../components/cv/employers"
import Hero from "../../components/cv/hero"

const simplifyStructure = results => {
  return results.edges.map(r => r.node)
}

const organizeSkills = skills => {
  return skills.edges.reduce((m, e) => {
    const skill = e.node
    m[skill.kind] ? m[skill.kind].push(skill) : (m[skill.kind] = [skill])
    return m
  }, {})
}

const CV = () => {
  const results = useStaticQuery(graphql`
    query Projects {
      skills: allSkillsYaml(sort: { fields: kind, order: ASC }) {
        edges {
          node {
            kind
            level
            name
          }
        }
      }
      projects: allProjectsYaml(sort: { fields: start_date, order: DESC }) {
        edges {
          node {
            context
            duration
            employer
            github
            missions
            personal_project
            project_name
            start_date(formatString: "MMMM YYYY")
            technologies
            website
          }
        }
      }
      employers: allEmployersYaml {
        edges {
          node {
            name
            start(formatString: "MMMM YYYY")
            end(formatString: "MMMM YYYY")
            description
          }
        }
      }
    }
  `)

  const projects = simplifyStructure(results.projects)
  const employers = simplifyStructure(results.employers)
  const skills = organizeSkills(results.skills)

  return (
    <Layout>
      <Helmet>
        <link rel="profile" href="https://microformats.org/profile/hcard" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@bobylito" />
        <meta
          name="twitter:title"
          content="Alex - front tech lead / freelance"
        />
        <meta
          name="twitter:description"
          content="Alex is a front tech-lead with more than 13 years of experience. He loves React and Gatsby and he will help you make better products."
        />
      </Helmet>

      <Hero className="print:hidden">
        <Contact />
      </Hero>

      <ContentContainer>
        <div className="hidden print:block">
          <Contact />
        </div>

        <Intro />

        <H2>Skills</H2>
        <Skills skills={skills} />

        <PageBreak />
        <H2>Experiences</H2>
        <Employers employers={employers} projects={projects} />

        <PageBreak />
        <H2>Projects</H2>
        <Projects projects={projects} />
      </ContentContainer>
    </Layout>
  )
}

export default CV
