import React from "react"
import { map } from "lodash"

import { H3 } from "../typography"

import classnames from "classnames"

const Tile = ({ isActive }) => (
  <span
    className={classnames("block pt-1 mt-1 w-1/5", {
      "bg-green-800": isActive,
      "bg-white": !isActive,
    })}
  />
)

const levels = [1, 2, 3, 4, 5]
const Skills = ({ skills }) => {
  return (
    <div className="flex flex-row flex-wrap">
      {map(skills, (values, type) => {
        values.sort((a, b) => b.level - a.level)
        return (
          <div className="w-full sm:w-1/2 md:w-1/3 print:w-1/3">
            <H3 className="capitalize">{type}</H3>
            <ul>
              {values.map(v => (
                <li className="pt-2">
                  <p className="text-lg capitalize font-body">{v.name}</p>
                  <div className="flex flex-row pr-4">
                    {levels.map(l => (
                      <Tile isActive={v.level >= l} />
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default Skills
