import React from "react"
import { H3, P, A, Strong } from "../typography"

import unified from "unified"
import remarkReact from "remark-react"
import remarkParse from "remark-parse"
import GithubSlugger from "github-slugger"

import Date from "./date"

const Employer = ({
  projects,
  employer: { name, website, start, end, description },
}) => {
  const slugger = new GithubSlugger()
  slugger.reset()
  const relatedProjects = projects.filter(p => p.employer === name)

  return (
    <div>
      <H3>{name}</H3>
      <Date start={start} end={end} />
      {website && <A href={website}>{website}</A>}
      <P className="description markdown-content">
        {
          unified()
            .use(remarkParse)
            .use(remarkReact)
            .processSync(description).contents
        }
      </P>
      <Strong className="print:hidden">Related projects:</Strong>
      <P className="print:hidden">
        <ul>
          {relatedProjects.map(p => (
            <li className="list-disc list-inside">
              <A href={`#${slugger.slug(p.project_name)}`}>{p.project_name}</A>
            </li>
          ))}
        </ul>
      </P>
    </div>
  )
}

export default Employer
