import React from "react"
import { H3, P, A, Strong } from "../typography"

import unified from "unified"
import remarkReact from "remark-react"
import remarkParse from "remark-parse"
import GithubSlugger from "github-slugger"

import Date from "./date"

const Project = ({
  project: {
    project_name,
    employer,
    start_date,
    duration,
    context,
    missions,
    technologies,
    github,
    personal_project,
    website,
  },
}) => {
  const slugger = new GithubSlugger()
  return (
    <div>
      <H3 id={slugger.slug(project_name)}>{project_name}</H3>
      <Date start={start_date} duration={duration} />
      {website && <A href={website}>{website}</A>}
      <P className="description markdown-content">
        {
          unified()
            .use(remarkParse)
            .use(remarkReact)
            .processSync(context).contents
        }
      </P>
      {missions && (
        <>
          <Strong>Roles:</Strong>
          <P>
            <ul className="list-disc list-inside">
              {missions.map(m => (
                <li>{m}</li>
              ))}
            </ul>
          </P>
        </>
      )}
      {technologies && (
        <div style={{ breakInside: "avoid" }}>
          <Strong>Technologies used:</Strong>
          <ul className="font-body">
            {technologies.map(t => (
              <li className="inline-block px-2 py-1 mt-2 mr-2 text-sm border border-gray-500 rounded-full">
                {t}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Project
