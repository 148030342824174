import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Intro = () => {
  const { content } = useStaticQuery(graphql`
    query MyQuery {
      content: markdownRemark(fileAbsolutePath: { regex: "/intro.md/" }) {
        html
      }
    }
  `)
  return (
    <div
      className="pt-12 markdown-content print:pt-4"
      dangerouslySetInnerHTML={{ __html: content.html }}
    ></div>
  )
}

export default Intro
