import React from "react"
import { A } from "../typography"

const textShadow = "0.05rem 0.05rem 0.1rem rgba(200,200,200,0.8)"
const Contact = () => {
  return (
    <div className="flex flex-col justify-around h-full contact-info vcard print:flex-row ">
      <div className="print:w-2/3 md:pt-16 print:p-0">
        <h1
          className="text-4xl md:text-6xl fn font-display print:text-3xl"
          style={{
            textShadow,
          }}
        >
          Alexandre <br />
          Valsamou-Stanislawski
        </h1>

        <h2
          className="pt-4 text-2xl font-display md:text-4xl md:pt-16 print:text-xl print:pt-4"
          style={{
            textShadow,
          }}
        >
          Software engineer
        </h2>
      </div>

      <ul
        className="flex flex-col justify-around h-32 text-xl font-semibold font-body print:w-1/3 print:text-lg print:text-right md:pt-20 print:p-0"
        style={{
          direction: "rtl",
        }}
      >
        <li>
          <A
            className="font-semibold email print:font-thin"
            href="alex@noima.xyz"
            style={{
              textShadow,
            }}
          >
            <span className="inline print:hidden">Email</span>
            <span className="hidden print:inline">alex@noima.xyz</span>
          </A>
        </li>

        <li>
          <A
            className="font-semibold print:font-thin"
            href="https://www.linkedin.com/in/astanislawski/"
            style={{
              textShadow,
            }}
          >
            <span className="inline print:hidden">LinkedIn</span>
            <span className="hidden print:inline">
              https://www.linkedin.com/in/astanislawski
            </span>
          </A>
        </li>

        <li>
          <A
            className="font-semibold print:font-thin"
            href="https://github.com/bobylito/"
            style={{
              textShadow,
            }}
          >
            <span className="inline print:hidden">GitHub</span>
            <span className="hidden print:inline">
              https://github.com/bobylito
            </span>
          </A>
        </li>
      </ul>
    </div>
  )
}

export default Contact
