import React from "react"

const formatDuration = duration => {
  const years = Math.floor(duration / 12)
  const months = duration - years * 12
  if (duration < 14) return `${duration} months`
  else if (years === 1) return `${years} year and ${months} months`
  else {
    return `${years} years and ${months} month${months > 1 ? "s" : ""}`
  }
}

const DateContent = ({ children }) => (
  <p className="italic font-body">{children}</p>
)

const Date = ({ start, end, duration }) => {
  if (duration) {
    return start === undefined ? (
      <DateContent>For {formatDuration(parseFloat(duration))}</DateContent>
    ) : (
      <DateContent>
        For {formatDuration(parseFloat(duration))} – started in {start}
      </DateContent>
    )
  }

  return !end ? (
    <DateContent>Since {start}</DateContent>
  ) : (
    <DateContent>
      From {start} until {end}
    </DateContent>
  )
}

export default Date
